<div class="w3-row">
    <div class="w3-col">
      <button type="submit" [routerLink]="['/']" routerLinkActive="router-link-active">
        <i class="fa fa-angle-double-right" style="font-size:24px"></i> BACK
      </button>
    </div>
    <div class="w3-col">
      <button type="submit" [routerLink]="['/']" routerLinkActive="router-link-active">
        <i class="fa fa-home" style="font-size:24px"></i>
      </button>
    </div>
  </div>
  
  <div class="form-group">
    <input type="search" placeholder="Search.." (input)="searchProduct($event.target.value)">
  </div>
  
  <div class="card">
    <!-- Card content goes here -->
  </div>
  
  <!-- Other HTML content goes here -->
   



<div *ngIf="productList.length==0">
    <h1 style="text-align: center;color: black;font-weight: 600;font-size: 22px;
    margin-top: 26px;">No Product Found</h1>
    </div> 

    <div class="products-area" style="margin-top: 1rem;">
        <p style="text-align: center;color: black;
        margin-top: 26px;">.</p>
    
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let Content of productList">
                    <div class="single-products-box">
                        <div class="products-image">
                            <div class="container">
                                <div class="card align-items-center">
                                    <img [src]="Content.Imagepath" (click)=showDetails(Content._id) style="cursor: pointer;" alt="image"> 
                                    <div class="products-content card-block text-center" style="border-top: 1px solid #ddd;">
                                        <h3><a (click)=showDetails(Content._id) style="cursor: pointer; font-size: 20px;">{{Content.name}}</a></h3>
                                        <div class="price">
                                            <span class="new-price">{{Content.purchaseAmount| currency:"INR"}}</span>
                                            <span class="old-price"><s>{{Content.pricePerUnit | currency:"INR"}}</s></span> 
                                        </div>
                                        
                                    </div>
                                    <div class="dis-circle">{{Content.priceOffPerc}}%</div>
                                </div>
                
                            </div>
                        </div>
                        <span class="on-sale" *ngIf="Content.sale">{{Content.priceOffPerc}}</span>
                        <div class="out-of-stock" *ngIf="Content.outOfStock">{{Content.outOfStock}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="visibility: hidden;">
     <p>abcdefghijklmnopqrstuvwxyz</p>
    
    </div>




