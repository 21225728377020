import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-misconceptions',
  templateUrl: './blog-misconceptions.component.html',
  styleUrls: ['./blog-misconceptions.component.scss']
})
export class BlogMisconceptionsComponent implements OnInit {
 

  constructor(private meta:Meta, public title:Title) { 

  }

  ngOnInit(): void {
  }
  pageTitle = [
    {
      bgImage: 'assets/img/blog.jpg',

      // title: 'About Us'
    }
  ]


 
}
