import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-best-dog-foodblog',
  templateUrl: './best-dog-foodblog.component.html',
  styleUrls: ['./best-dog-foodblog.component.scss']
})
export class BestDogFoodblogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  pageTitle = [
    {
      bgImage: 'assets/img/blog.jpg',
        // title: 'Spotlight on the Natuzzi Editions Furniture Collection'
    }
]

}
