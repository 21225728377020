<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1 style="color: #FBDA6F;">{{Content.title}}</h1>
    <ul>
        <!-- <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li> -->
    </ul>
</div>
<!-- End Page Title Area -->

<!-- Start Products Area -->
<section class="products-area" style="margin-top: 1rem;">
    <p style="text-align: center;color: black;font-weight: 600;font-size: 30px;font-family: 'Times New Roman', Times, serif;">We found these products available for you.</p>

    <div class="container">
        <!-- <div class="fiwan-grid-sorting row align-items-center">
            <div class="col-lg-8 col-md-6 result-count">
                <div class="d-flex align-items-center">
                    data-bs-target="#productsFilterModal" 
                    <a href="#" data-bs-toggle="modal" class="sidebar-filter"><i class='bx bx-filter'></i> Filter</a>
                    <p>We found these products available for you.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 ordering">
                <div class="select-box">
                    <label>Sort By:</label>
                    <ngx-select-dropdown (searchChange)="searchChange($event)" tabindex="0" [multiple]="false" [(ngModel)]="singleSelect" [config]="config" [options]="options"></ngx-select-dropdown>
                </div>
            </div>
        </div> -->

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let Content of product_list | paginate: { itemsPerPage: 9, currentPage: shopFullWidth }">
                <div class="single-products-box">
                    <div class="products-image">
                        <div class="container">
                            <div class="card align-items-center" >
                               <a href="https://spotyourdeal.com/product-detail/{{Content._id}}" target="_blank" style="cursor: pointer;"><img [src]="Content.Imagepath" alt="image"></a>
                                <!-- <div class="card-block text-center">
                                    <h4 class="card-title">Card title</h4>
                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                </div> -->
                                <div class="products-content card-block text-center" style="border-top: 1px solid #ddd;font-size: 20px;">
                                    <h3><a href="https://spotyourdeal.com/product-detail/{{Content._id}}" target="_blank" style="cursor: pointer;">{{Content.name}}</a></h3>
                                    <div class="price">
                                        <span class="new-price">{{Content.purchaseAmount | currency:"INR"}}</span>
                                        <span class="old-price">{{Content.pricePerUnit | currency:"INR"}} </span> 
                                    </div>
                                </div>
                                <div class="dis-circle">{{Content.priceOffPerc}}%</div>
                            </div>
                        </div>
                      
                        <!-- <a routerLink="/{{Content.detailsLink}}" class="d-block"> -->
                            <!-- <a routerLink="['simple-product']" [state]="{ productList:Content}" class="d-block">  </a> -->
                            <!-- <img [src]="Content.Imagepath" (click)=showDetails(Content._id) alt="image">          -->
                        <!-- <ul class="products-button">
                            <li><a routerLink="/wishlist"><i class='bx bx-heart'></i></a></li>
                            <li><a href="#" data-bs-toggle="modal" data-bs-target="#productsQuickView"><i class='bx bx-show-alt'></i></a></li>
                            <li><a href="{{Content.mainImg}}" class="popup-btn"><i class='bx bx-scan'></i></a></li>
                            <li><a routerLink="/{{Content.detailsLink}}"><i class='bx bx-link'></i></a></li>
                        </ul> -->
                        <!-- <a routerLink="/cart" class="add-to-cart-btn"><i class="bx bx-cart"></i> Add To Cart</a> -->
                    </div>
                    <!-- <div class="products-content">
                        <h3><a routerLink="/{{Content.detailsLink}}">{{Content.name}}</a></h3>
                        <div class="price">
                            <span class="new-price">{{Content.purchaseAmount| currency:"INR"}}</span>
                            <span class="old-price" *ngIf="Content.oldPrice">{{Content.price| currency:"INR"}}</span>
                        </div>
                    </div> -->
                    <span class="on-sale" *ngIf="Content.sale">{{Content.priceOffPerc}}</span>
                    <div class="out-of-stock" *ngIf="Content.outOfStock">{{Content.outOfStock}}</div>
                </div>
            </div>

            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="pagination-area text-center" style="margin-bottom: 2rem;">
                    <pagination-controls (pageChange)="shopFullWidth = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Products Area -->