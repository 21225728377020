import { Component, OnInit } from '@angular/core';
import {Meta, Title } from '@angular/platform-browser'; 
@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

    constructor(private meta: Meta, public title: Title) {
        this.title.setTitle("Contact Us - PetVays | Buy Best Pet Supplies And Accessories");
        this.meta.addTags([
        { name: 'description', content: 'Contact Us for All Your Pet Needs. PetVays is The Best Online Store for Quality Dog Food Products at an Affordable Price.' },
        { name: "robots", content: "index,follow"},
        { property: "og:type", content: "website"},
        { property: "og:url", content: "https://petvays.com/contact"},
        { property: "og:title", content: "Contact Us - PetVays | Buy Best Pet Supplies And Accessories"},
        { property: "og:description", content: "Contact Us for All Your Pet Needs. PetVays is The Best Online Store for Quality Dog Food Products at an Affordable Price."},
        { property: "og:image", content: "http://petvays.com/assets/img/contactpage.jpg"},
        {property: "twitter:url", content: "https://petvays.com/contact"},
        {property: "twitter:title", content: "Contact Us - PetVays | Buy Best Pet Supplies And Accessories"},
        {property: "twitter:description", content: "Contact Us for All Your Pet Needs. PetVays is The Best Online Store for Quality Dog Food Products at an Affordable Price."},
        {property: "twitter:image", content: "http://petvays.com/assets/img/contactpage.jpg"},
        {rel: 'canonical', href: 'https://petvays.com/contact'}, 
        
		  ]);  
        }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/contactpage.jpg',
            title: 'Contact Us'
        }
    ]

    submit(form){
        var name = form.name;
        console.log(name);
        
        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);
        
        var message = form.message;
        console.log(message);
    }

}