<!-- Start Main Banner Area -->
<div class="home-banner-area mt-4 mb-5">
    <div class="container-fluid">
        <img src="assets\img\images\Dog-Food.jpg" alt="" height="100%" width="100%">
        <div class="home-slides">
            <owl-carousel-o [options]="homeSlidesOptions">
                <ng-template carouselSlide *ngFor="let Content of mainBannerItem;">
                    <div class="main-banner-area">
                        <div class="row">
                            <div class="col-lg-5 col-md-12">
                                <div class="main-banner-content">
                                    <span class="sub-title" style="color:#666666 ;">{{Content.subTitle}}</span>
                                    <h1>{{Content.title}}</h1>
                                    <p>{{Content.paragraph}}</p>
                                    <a routerLink="/{{Content.buttonLink}}" class="default-btn"><i class="{{Content.buttonIcon}}"></i> {{Content.buttonText}}</a>
                                </div>
                            </div>
         
                            <div class="col-lg-7 col-md-12">
                                <video style="width: 100%; height: 550px;" autoplay loop class="library" preload="auto" data-config="some-js-object-here">
                                    <source [src]="Content.image" type='video/mp4'>
                                </video>
                                <div class="main-banner-image text-center" style="background-image: url({{Content.image}});">
                                    <!-- <img [src]="Content.image" alt="image"> -->
                                    
                                    <!-- <video style="width: 100%; height: 280px;" class="library" controls preload="auto" data-config="some-js-object-here">
                                        <source [src]="Content.img" type='video/mp4'>
                                    </video> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>

    <!-- <ul class="banner-social-links">
        <li *ngFor="let Links of bannerSocialLinks;"><a href="{{Links.link}}" target="_blank"><i class="{{Links.icon}}"></i></a></li>
    </ul> -->

    <!-- <div class="banner-contact-info">
        <ul *ngFor="let Info of bannerContactInfo;">
            <li><a href="tel:{{Info.number}}"><i class='bx bx-phone-call'></i> {{Info.number}}</a></li>
            <li><a href="mailto:{{Info.email}}"><i class='bx bx-envelope'></i> {{Info.email}}</a></li>
        </ul>
    </div> -->
</div>
<!-- End Main Banner Area -->