import { Component, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { windowCount } from 'rxjs/operators';
import { APIS } from 'src/app/config';
import { PetvaysServiceService } from 'src/app/petvays-service.service';

@Component({
    selector: 'app-shop-full-width-page-one',
    templateUrl: './shop-full-width-page-one.component.html',
    styleUrls: ['./shop-full-width-page-one.component.scss']
})
export class ShopFullWidthPageOneComponent implements OnInit {
    Content: any;
    product_list: any;
    jsonLD: any;
    id: any;
    productId: any;
    metaDescription=localStorage.getItem('metaDescription');
    constructor(private renderer: Renderer2, private router: Router, private sanitizer: DomSanitizer, public title: Title, public meta: Meta, private activatedRoute: ActivatedRoute, private http: PetvaysServiceService) {
        // this.title.setTitle("Buy Dog Food | Biscuits, Munchies & Bones At Best Price in India | PetVays");
        // this.meta.addTags([
        //     { name: "description", content: "PetVays is the most trusted online store for quality dog food products at affordable prices in India. Shop our range of Dog Food in Biscuits, Munchies, and Bones to keep your pets healthy and happy." },
        //     { name: "keywords", content: "Milk Biscuits for Dogs, Chicken Biscuits for Dogs, Chicken Munchies for Dog, Munchies For Dog, Mutton Munchies for Dog, Rawhide Bones For Dog" },
        //     // Meta robots
        //     { name: "robots", content: "index,follow" },
        //     // OG Tag Facebook
        //     { property: "og:type", content: "website" },
        //     { property: "og:url", content: "https://petvays.com/collection-dog-food" },
        //     { property: "og:title", content: "Buy Dog Food | Biscuits, Munchies & Bones At Best Price in India | PetVays" },
        //     { property: "og:description", content: "PetVays is the most trusted online store for quality dog food products at affordable prices in India. Shop our range of Dog Food in Biscuits, Munchies, and Bones to keep your pets healthy and happy." },
        //     { property: "og:image", content: "http://petvays.com/assets/img/biscuit.jpg" },
        //     // Twitter Card
        //     { property: "twitter:url", content: "https://petvays.com/collection-dog-food" },
        //     { property: "twitter:title", content: "Buy Dog Food | Biscuits, Munchies & Bones At Best Price in India | PetVays" },
        //     { property: "twitter:description", content: "PetVays is the most trusted online store for quality dog food products at affordable prices in India. Shop our range of Dog Food in Biscuits, Munchies, and Bones to keep your pets healthy and happy." },
        //     { property: "twitter:image", content: "http://petvays.com/assets/img/biscuit.jpg" },
        //     // Canonical URL
        //     { rel: 'canonical', href: 'https://petvays.com/collection-dog-food' },
        // ]);
    }

    ngOnInit(): void {
        this.productlist();
        this.resetOption = [this.options[0]];

        const json = {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Dog Food",
            "image": "http://petvays.com/assets/img/biscuit.jpg",
            "description": "Dog food, biscuits and munchies, chews, bones, and more.",
            "brand": {
                "@type": "Brand",
                "name": "PetVays"
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.4",
                "ratingCount": "79"
            }
        };
        this.jsonLD = this.getSafeHTML(json);
        const canonicalURL = `https://petvays.com/collection-dog-food`;
        const link = this.renderer.createElement('link');
        this.renderer.setAttribute(link, 'rel', 'canonical');
        this.renderer.setAttribute(link, 'href', canonicalURL);
        this.renderer.appendChild(document.head, link);
        const pageTitle = `Buy Dog Food | Biscuits, Munchies & Bones At Best Price in India | PetVays`;
        this.title.setTitle(pageTitle);
        this.meta.addTags([
           
          // Meta robots
          { name: "robots", content: "index,follow" },
          // OG Tag Facebook
          { property: "og:type", content: "website" },
          { property: "og:url", content: canonicalURL },
          { property: "og:title", content:  pageTitle},
          { property: "og:description", content: this.metaDescription},
      
         
      ]);
    }
    getSafeHTML(value: {}) {
        const json = JSON.stringify(value, null, 2);
        const html = `<script type="application/ld+json">${json}</script>`;
        // Inject to inner html without Angular stripping out content
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    pageTitle = [
        {
            bgImage: 'assets/img/images/product.jpg',
            // title: 'Your Pets Deserve The Best'
        }
    ]
    // singleProductsBox = [
    //     {
    //         mainImg: 'assets/img/images/Biscuits brown.jpg',
    //         title: 'PetVays All Life Stages Chicken Flavour Real Chicken Biscuit 900Gms',
    //         newPrice: '199.00',
    //         oldPrice: '',
    //         sale: '',
    //         outOfStock: '',
    //         detailsLink: 'simple-product'
    //     },
    //     {
    //         mainImg: 'assets/img/images/Biscuits.jpg',
    //         title: 'PetVays Oven Baked Milk Dog Biscuits, Calcium Rich Dog Treat 900Gms',
    //         newPrice: '199.00',
    //         oldPrice: '',
    //         sale: '',
    //         outOfStock: '',
    //         detailsLink: 'simple-product'
    //     },
    //     {
    //         mainImg: 'assets/img/images/muchies .jpg',
    //         title: 'PetVays All Life Stages Munchy Sticks, Chicken Flavour, Dog Treats',
    //         newPrice: '199.00',
    //         oldPrice: '',
    //         sale: '',
    //         outOfStock: '',
    //         detailsLink: 'simple-product'
    //     },
    //     {
    //         mainImg: 'assets/img/images/muchies packet.jpg',
    //         title: 'PetVays All Life Stages Munchy Sticks, Mutton Flavour, Dog Treats',
    //         newPrice: '199.00',
    //         oldPrice: '',
    //         sale: '',
    //         outOfStock: '',
    //         detailsLink: 'simple-product'
    //     },
    //     {
    //         mainImg: 'assets/img/images/Rawhide bones .jpg',
    //         title: 'PetVays Healthy Rawhide Bone Treat for Dog Healthcare 250Gms',
    //         newPrice: '229.00',
    //         oldPrice: '',
    //         sale: '',
    //         outOfStock: '',
    //         detailsLink: 'simple-product'
    //     },
    //     {
    //         mainImg: 'assets/img/images/Rawhide bones big.jpg',
    //         title: 'PetVays Healthy Rawhide Bone Treat for Dog Healthcare 500Gms',
    //         newPrice: '399.00',
    //         oldPrice: '',
    //         sale: '',
    //         outOfStock: '',
    //         detailsLink: 'simple-product'
    //     },
    //     {
    //         mainImg: 'assets/img/images/Biscuits brown.jpg',
    //         title: 'PetVays All Life Stages Chicken Flavour Real Chicken Biscuit 450Gms',
    //         newPrice: '149.00',
    //         oldPrice: '',
    //         sale: '',
    //         outOfStock: '',
    //         detailsLink: 'simple-product'
    //     },
    //     {
    //         mainImg: 'assets/img/images/Biscuits.jpg',
    //         title: 'PetVays Oven Baked Milk Dog Biscuits, Calcium Rich Dog Treat 450Gms',
    //         newPrice: '159.00',
    //         oldPrice: '',
    //         sale: '',
    //         outOfStock: '',
    //         detailsLink: 'simple-product'
    //     },
    //     {
    //         mainImg: 'assets/img/images/muchies .jpg',
    //         title: 'PetVays All Life Stages Munchy Sticks, Chicken Flavour, Dog Treats',
    //         newPrice: '199.00',
    //         oldPrice: '',
    //         sale: '',
    //         outOfStock: '',
    //         detailsLink: 'simple-product'
    //     },
    //     {
    //         mainImg: 'assets/img/images/Rawhide bones .jpg',
    //         title: 'PetVays Healthy Rawhide Bone Treat for Dog Healthcare 250Gms',
    //         newPrice: '229.00',
    //         oldPrice: '',
    //         sale: '',
    //         outOfStock: '',
    //         detailsLink: 'simple-product'
    //     },
    //     {
    //         mainImg: 'assets/img/images/muchies packet.jpg',
    //         title: 'PetVays All Life Stages Munchy Sticks, Mutton Flavour, Dog Treats',
    //         newPrice: '199.00',
    //         oldPrice: '',
    //         sale: '',
    //         outOfStock: '',
    //         detailsLink: 'simple-product'
    //     },
    //     {
    //         mainImg: 'assets/img/images/Biscuits.jpg',
    //         title: 'PetVays Oven Baked Milk Dog Biscuits, Calcium Rich Dog Treat 900Gms',
    //         newPrice: '199.00',
    //         oldPrice: '',
    //         sale: '',
    //         outOfStock: '',
    //         detailsLink: 'simple-product'
    //     }
    // ]
    // For Routing to details page
    productlist() {
        console.log(this.productId,"hello")
        let formdata = {
            "limit": 20,
            "storeid": "Pet6197",
            "offset": 0,
        }
        console.log("Response from formdata of productlist", formdata)
        this.http.post(APIS.productList, formdata, true)
            .subscribe((res: any) => {
                this.product_list = res;
                const products = this.product_list;
                console.log("product detail API hit1", products)
      
                // SEO
                let metaDescription = '';
                let keywords="";
                products.forEach((product: any) => {
                  metaDescription += `${product.name}, `;
                  keywords += `${product.name}, `;
                });
                metaDescription = metaDescription.slice(0, -2);
                this.meta.updateTag({ name: 'keywords', content: keywords });
              
              
                // Update the meta tag description
                this.meta.updateTag({ name: 'description', content: metaDescription });
                localStorage.setItem('metaDescription', metaDescription);
                
                let Imagepath = '';
                products.forEach((product: any) => {
                  Imagepath += `${product.Imagepath}, `;
                });
                this.meta.updateTag({ property: 'og:image', content: Imagepath });
      
                // END SEO
                console.log("Response from product list API", this.product_list)
            }, (error: any) => {
                console.log(error)
            });
    }
    // showDetails(item: any) {
    //     // this.router.navigateByUrl('/simple-product', { state: { productId : item } });
    //     this.router.navigate(['/simple-product', item])
    // }
 
    // For Pagination
    shopFullWidth: number = 1;

    // Category Select
    singleSelect: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    config = {
        displayKey: "name",
        search: true
    };
    options = [
        {
            name: "Default",
        },
        {
            name: "Popularity",
        },
        {
            name: "Latest",
        },
        {
            name: "Price: low to high",
        },
        {
            name: "Price: high to low",
        }
    ];
    searchChange($event) {
        console.log($event);
    }
    reset() {
        this.resetOption = [];
    }

}