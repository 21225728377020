<!-- Start Footer Area -->
<footer class="footer-area pt-100 {{bgClass}}" style="background-color: #f5f5f5;">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo d-inline-block">
                        <img src="assets/img/images/logo.png" alt="logo" height="120px" width="120px">
                    </a>
                    <p>Pets are at the heart of everything we do here, they make our lives whole. With each collection, we do our best to honour them.</p>
                    <ul class="social-links">
                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li> -->
                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li> -->
                        <li><a href="https://instagram.com/petvays?igshid=YmMyMTA2M2Y=" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="https://www.facebook.com/petvays" target="_blank"><i class="fa fa-facebook-square"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCu2Tgvkiwn6u9KXen1ArZNQ/" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                        <li><a href="https://in.pinterest.com/petvays/" target="_blank"><i class="bx bxl-pinterest"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Shop Now</h3>
                    <ul class="links-list">
                        <li><a routerLink="/collection-dog-food">Dogs</a></li>
                        <li><a routerLink="/accessories">Accessories</a></li>
                        <li><a href="javascript:void(0)">Cats (Coming Soon)</a></li>                       
                        <li><a href="javascript:void(0)">Rabbits (Coming Soon)</a></li>
                        <li><a href="javascript:void(0)">Fish (Coming Soon)</a></li>
                        <!-- <li><a routerLink="/shop-full-width-1">Shoes</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Get To Know Us</h3>
                    <ul class="links-list">
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-of-service">Terms of Service</a></li>
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                        <li><a routerLink="/gallery">Gallery</a></li>
                        <li><a routerLink="/blogs">Blogs</a></li>
                        <li><a routerLink="/faqs">FAQs</a></li>
                        
                        
                        <!-- <li><a routerLink="/customer-service">Return Policy</a></li> -->
                        <!-- <li><a routerLink="/terms-and-conditions">Terms & Conditions</a></li> -->
                        <!-- <li><a routerLink="/about">About Us</a></li> -->
                        <!-- <li><a routerLink="/about">Careers</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contacts</h3>
                    <ul class="footer-contact-info">
                        <li>Address:</li>
                        <li>D-1375/5 1st Floor, Sector 1, Block D, Indiranagar, Lucknow</li>
                        <li>Opposite to Dolphin Public School, Tiger Plaza, Chipiyana Buzurg,Lal Kuan, Ghaziabad</li>
                        <li>Email: <a href="mailto:info@petvays.com">info@petvays.com</a></li>
                        <li>Phone: <a href="tel:(+91)8630649605">(+91)8630649605</a></li>
                        <!-- <li>Fax: <a href="tel:+55587154756">+55 587 154756</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                    <p> Copyright © 2022  <strong style="color: #000;font-weight:900;">PetVays</strong>. All Rights Reserved.<a href="https://petvays.com.com/" target="_blank"></a></p>
                </div>

                <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a routerLink="/customer-service">Privacy Policy</a></li>
                        <li><a routerLink="/customer-service">Terms & Conditions</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Start Sidebar Modal Area -->
<div class="sidebarModal modal right fade" id="sidebarModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal"><i class='bx bx-x'></i></button>
            <div class="modal-body">
                <div class="logo">
                    <a href="index.html" class="d-inline-block"><img src="assets/img/images/logo.png" alt="image" height="150px" width="150px"></a>
                </div>
                <div class="instagram-list">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/gallery/delicious-treats.jpg" alt="image">
                                <!-- <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a> -->
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/gallery/meal-time.jpg" alt="image">
                                <!-- <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a> -->
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/gallery/natural-perfume.jpg" alt="image">
                                <!-- <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a> -->
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/gallery/yummy-munchies.jpg" alt="image">
                                <!-- <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a> -->
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/gallery/tasty-treats.jpg" alt="image">
                                <!-- <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a> -->
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/gallery/Shampooo.jpg" alt="image">
                                <!-- <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sidebar-contact-info">
                    <h4>
                        <a href="tel:(+91)8630649605">(+91)8630649605</a><br><br>
                        <span>OR</span><br><br>
                        <a href="mailto:info@petvays.com">info@petvays.com</a>
                    </h4>
                </div>
                <br>
                <ul class="social-list">
                    <li><span style="color: #666666;">Follow Us On:</span></li>
                  <!-- <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>  -->
                    <li><a href="https://instagram.com/petvays?igshid=YmMyMTA2M2Y=" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="https://www.facebook.com/petvays" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://www.youtube.com/channel/UCu2Tgvkiwn6u9KXen1ArZNQ" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                    <li><a href="https://in.pinterest.com/petvays/" target="_blank"><i class="bx bxl-pinterest"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Sidebar Modal Area -->

<!-- Start QuickView Modal Area -->
<!-- End QuickView Modal Area -->

<!-- Start Sidebar Modal Area -->
 
<!-- End Sidebar Modal Area -->