<!-- Start Top Header Area -->
<section class="top-header-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <ul class="top-header-contact-info">
                    <li><a href="(+91)8630649605" style="color: antiquewhite;"><i class='bx bx-phone-call'></i>(+91)8630649605</a></li>
                    <li><a href="mailto:info@petvays.com" style="color: antiquewhite;"><i class='bx bx-envelope'></i> info@petvays.com</a></li>
                    </ul>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="top-header-right">
                    <!-- <ul class="d-flex align-items-center justify-content-end">
                        <li><a><marquee width="800px" direction="left"><b>Shop For ₹5000/- and get ₹500/- Product Coupon and Buy any 2 products Get 10% Extra off, T&C Apply</b></marquee></a></li>
     </ul> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Top Header Area -->

<!-- Start Navbar Area -->

<div class="navbar-area">
    <div class="fiwan-responsive-nav">
        <div class="container">
            <div class="fiwan-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/logo.png" alt="logo" style="width: 138px;"></a>
                    <!-- assets/img/logo1.png -->
                </div>
            </div>
        </div>
    </div>



    <div class="fiwan-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <div class="d-flex flex-column align-items-center justify-content-center text-center w-100">
                    <!-- Centered logo -->
                    <a class="navbar-brand  mt-2" style="padding-left: 15%;">
                        <img routerLink="/" src="assets/img/logo1.png" alt="logo" style="width: 138px;">
                    </a>
    
                    <!-- Centered menu -->
                    <div class="collapse navbar-collapse mean-menu" style="padding-left: 19%;">
                        <ul class="navbar-nav ">
                            <!-- Centered menu items -->
                            <li class="nav-item">
                                <a routerLink="/" class="nav-link mx-4" style="font-weight: 400; font-size: 20px; word-spacing: 5%;">Home</a>
                            </li>
                            
                            <li class="nav-item dropdown">
                                <a href="javascript:void(0)" class="dropdown-toggle nav-link mx-4" style="font-weight: 400; font-size: 20px; word-spacing: 5%;">Shop</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/collection-dog-food" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Dogs</a></li>
                                    <li class="nav-item"><a href="javascript:void(0)">Cats (Coming Soon)</a></li>
                                    <li class="nav-item"><a href="javascript:void(0)">Rabbits (Coming Soon)</a></li>
                                    <li class="nav-item"><a href="javascript:void(0)">Fish (Coming Soon)</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/accessories" class="nav-link mx-4" style="font-weight: 400; font-size: 20px; word-spacing: 5%;">Accessories</a>
                            <li class="nav-item"><a routerLink="/blogs" class="nav-link mx-4" style="font-weight: 400; font-size: 20px; word-spacing: 5%;">Blog</a>
                            <li class="nav-item"><a routerLink="/about" class="nav-link mx-4" style="font-weight: 400; font-size: 20px; word-spacing: 5%;">About Us</a></li>
                            <li class="nav-item"><a routerLink="/contact" class="nav-link mx-4" style="font-weight: 400; font-size: 20px; word-spacing: 5%;">Contact Us</a></li>

                        </ul>
                    </div>
    
                    
                </div>
                 <!-- Contact link container -->
                 <!-- <div>
                    <div class="top">
                        <a routerLink="/contact" class="nav-link">
                            <i class="fa fa-phone" style="font-size: 24px;"></i>
                        </a>
                    </div>
                </div> -->
                <div class="others-option d-flex align-items-center top">
                    <div class="option-item">
                        <div class="search-box">
                            <i routerLink="/search-result" class='bx bx-search'></i>
                        </div>
                    </div>

                    <!-- <div class="option-item">
                        <a routerLink="/cart" class="cart-btn"><i class='bx bx-shopping-bag'></i></a>
                    </div> -->

                    <div class="option-item">
                        <div class="side-menu-btn">
                            <i class="bx bx-menu-alt-left" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    

</div>
 <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            
            <div class="container">
                <div class="option-inner">
                    <div class="others-option d-flex align-items-center justify-content-center">
                        <div class="option-item">
                            <div class="search-box">
                                <i routerLink="/search-result" class='bx bx-search'></i>
                            </div>
                        </div>
                   <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="bx bx-menu-alt-left" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!-- End Navbar Area -->

<!-- Search Overlay -->
<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            
            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <!-- <div class="search-overlay-form">
                <form>
                    <input type="text"  class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search'></i></button>
                </form>
            </div> -->
        </div>
    </div>
</div>
<!-- End Search Overlay -->

