<!-- Start Blog Area -->
<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title" *ngFor="let Content of sectionTitle;">
            <h2>{{Content.title}}</h2>
            <p>{{Content.paragraph}}</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" *ngFor="let Content of singleBlogPost;">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/{{Content.link}}" class="d-block">
                            <!-- <img [src]="Content.img" alt="image"> -->
                            <video style="width: 100%; height: 280px;" class="library" controls preload="auto"
                                data-config="some-js-object-here">
                                <source [src]="Content.img" type='video/mp4'>
                            </video>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<section class="blog-area pt-100 pb-70">
    <div class="container">
        <h1 class="text-center">Distributor Details</h1><hr>
        <div class="row">
            
            <div class="col-lg-4 col-md-4 col-sm-12">
                <h2>Pet Gallery</h2>
                <hr>
                <p>Faizabad Road, Rabuganj, IT Chauraha<br>
                    District Lucknow U.P<br>
                    8858123452
                </p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <h2>Royal Pet Shop</h2>
                <hr>
                <p>B-block, Near Sai Mandir, Indranagar<br>
                    District Lucknow U.P<br>
                    8400129091
                </p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-4">
                <h2>Golden Valley Pet</h2>
                <hr>
                <p>Chauhan Market, Arjun Ganj<br>
                    District Lucknow U.P<br>
                    9565723777
                </p>
            </div>
            <hr>

            <div class="col-lg-4 col-md-4 col-sm-12">
                <h2>Dr. B.B Singh Memorial</h2>
                <hr>
                <p>Vikas Pet Care, C-434, CBlock <br>
                    District Lucknow U.P<br>

                </p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <h2>Divine Pet Clinic & Diagnostic Center</h2>
                <hr>
                <p>Munsipuliya
                    District Lucknow U.P<br>
                    7986946738
                </p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-4">
                <h2>Pet Shop.com(Mr. Kunal)</h2>
                <hr>
                <p>45, Lakshmanpuri, 226016<br>
                    District Lucknow U.P<br>
                    9140872008
                </p>
            </div>
            <hr>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <h2>K.K Pet Clinic & Pet Shop</h2>
                <hr>
                <p>Plot-11, Sitapur Road, Aziz Nagar<br>
                    District Lucknow U.P<br>
                    8770109906
                </p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <h2>The Puppy Patch(Naisha)</h2>
                <hr>
                <p>Vivek Khand-2, Gomtinagar<br>
                    District Lucknow U.P<br>
                    8318306378
                </p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12   mb-5">
                <h2>Pet Station Technology Pvt. Ltd.</h2>
                <hr>
                <p>D-1149, Indranagar, D-block<br>
                    District Lucknow U.P<br>
                    9962211834
                </p>
            </div><hr>
            <div class="col-lg-4 col-md-4 col-sm-12 mt-3">
              
                <h2>Dr. Vet Pet Clinic</h2>
                <hr>
                <p>Sector 17, 133 Picnic Spot Road, Near old <br>
                    Power House District Lucknow U.P<br>
                </p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mt-3">
                <h2>Anshul Singh</h2>
                <hr>
                <p>Gali No.1 Keshav Vihar Colony Dhanipur<br>
                    G.T Road Aligarh U.P<br>                 
                </p>

            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mt-3">
                <h2>Chandra Collection</h2>
                <hr>
                <p>D-2032, Indranagar District<br>
                     Lucknow U.P, 8953655700<br>
                    
                </p>
            </div>
           
           
        </div>
    </div>
</section>
<!-- End Blog Area -->