import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-dog-diet',
  templateUrl: './dog-diet.component.html',
  styleUrls: ['./dog-diet.component.scss']
})
export class DogDietComponent implements OnInit {
  jsonLD: any;

  constructor(private meta:Meta, public title:Title) { 
    this.title.setTitle("Is Your Dog's Diet Making Them Sick? 5 Ways to Pick the Right Food | Petvays");
		this.meta.addTags([
			{ name: 'description', content: 'If you love your dog, the last thing you want to do is hurt them. Unfortunately, dog food manufacturers aren’t always honest about what’s in their products and some dogs can suffer from food allergies or intolerances that cause discomfort, pain, or serious health problems if left untreated.' },
			{ name: "keywords", content: ""},
			 // Meta robots
			{ name: "robots", content: "index,follow"},
			  // OG Tag Facebook
			{ property: "og:type", content: "website"},
			{ property: "og:url", content: "https://petvays.com/blogs/is-your-dogs-diet-making-them-sick"},
			{ property: "og:title", content: "Is Your Dog's Diet Making Them Sick? 5 Ways to Pick the Right Food | Petvays"},
			{ property: "og:description", content: "If you love your dog, the last thing you want to do is hurt them. Unfortunately, dog food manufacturers aren’t always honest about what’s in their products and some dogs can suffer from food allergies or intolerances that cause discomfort, pain, or serious health problems if left untreated."},
			{ property: "og:image", content: "http://petvays.com/assets/img/dog.png"},
			 // Twitter Card
			{property: "twitter:url", content: "https://petvays.com/blogs/is-your-dogs-diet-making-them-sick"},
			{property: "twitter:title", content: "Is Your Dog's Diet Making Them Sick? 5 Ways to Pick the Right Food | Petvays"},
			{property: "twitter:description", content: "If you love your dog, the last thing you want to do is hurt them. Unfortunately, dog food manufacturers aren’t always honest about what’s in their products and some dogs can suffer from food allergies or intolerances that cause discomfort, pain, or serious health problems if left untreated."},
			{property: "twitter:image", content: "http://petvays.com/assets/img/dog.png"},
			 // Canonical URL
			{rel: 'canonical', href: 'https://petvays.com/blogs/is-your-dogs-diet-making-them-sick'},
			

		  ]);  
  }

  ngOnInit(): void {
  }
  pageTitle = [
    {
      bgImage: 'assets/img/blog.jpg',

      // title: 'About Us'
    }
  ]


  aboutContent = [
    {
      subTitle: `Is Your Dog's Diet Making Them Sick? 5 Ways to Pick the Right Food.`,
      //title: `We've Been Thriving in 37 Years In This Area`,
      paragraph: `If you love your dog, the last thing you want to do is hurt them. Unfortunately, dog food manufacturers aren’t always honest about what’s in their products and some dogs can suffer from food allergies or intolerances that cause discomfort, pain, or serious health problems if left untreated. By following these tips on how to pick the right food for your dog’s diet, you can ensure they won’t experience any of these problems while still getting the nutrition they need to grow strong and healthy with age.`,
      content: [
        {
          //title: `Our Goal`,
          //paragraph: `It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
        },
        {
          title: `What is dog food made of`,
          paragraph: `A lot of people think that dog food is made of low-quality, dry kibble bits, with a small amount of water, byproduct grease or vegetable oils mixed in and all sorts of weird animal byproducts like beaks or feathers. But if you buy your pet food online (and they deserve it!), you will see that these foods are much more likely to contain just animal protein, veggies and grains which is what their bodies need.`
        },
        {
          title: `Don't feed them human foods`,
          paragraph: 'If you feed your dog human foods they will most likely get sick, because they need a diet that is specific for their body type. If you are looking for the best pet food brand, it is recommended that you buy pet food online. You can find a variety of different brands and price ranges on sites such as Amazon or Chewy.'
        },
        {
          title: `Avoid These 14 Foods`,
          paragraph: 'Did you know that some foods can actually make your dog sick or give them digestive problems when they are consumed on a regular basis? Many of these foods are not just unhealthy for dogs, but people as well! While there are plenty of different treats out there for our dogs to enjoy. health instead of putting them at risk.'
        },
        {
          title: `The best proteins for dogs`,
          paragraph: 'One of the best sources of protein for your dog is chicken, but their human grade counterparts can be pricy and usually difficult to find. Another great source of protein that is easy to come by is Milk Biscuits for Dogs, which can help you ensure a high quality diet without breaking the bank. Protein also gives your pup a healthy, shiny coat and strong muscles and bones so be sure to do your research on other types as well! Best Pet Food Brand has been at this game for over 30 years!'
        },
        {
          title: `The best carbohydrates for dogs`,
          paragraph: 'Chicken Munchies for Dogs is a great food for dogs because it has all of the nutrients that dogs need.  Chicken munchies for dogs can be purchased at select pet stores near you. '
        },

      ]
    }
  ]
  aboutImage = [
    {
      img: 'assets/img/dog.png'
    },

  ]
}
