<!-- Start Main Banner Area -->  
<div class="home-slides-two">
    <div style="margin-bottom:5%;">
        <owl-carousel-o [options]="homeSlidesOptions">
          <ng-template carouselSlide *ngFor="let content of pageTitle">
            <div class="page-title-area" style="background-image: url({{content.bgImage}});height: 550px;">
              <!-- Add any other content or bindings here -->
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    <owl-carousel-o [options]="homeSlidesOptions">
        <ng-template carouselSlide *ngFor="let Content of mainBannerItem;">
            <div class="banner-area">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-12">
                            <div class="banner-content">
                                <h2 style="font-size: 32px">{{Content.title}}</h2>
                                <p>{{Content.paragraph}}</p>
                                <a routerLink="/{{Content.buttonLink}}" class="default-btn"><i class="{{Content.buttonIcon}}"></i> {{Content.buttonText}}</a>
                            </div>
                        </div>
        
                        <div class="col-lg-8 col-md-12">
                            <div class="banner-image bg-color1">
                                <img [src]="Content.image" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
<!-- End Main Banner Area -->